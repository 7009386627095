import React from 'react';
import { Link } from 'gatsby'
import StarIcon from '../../assets/images/star-icon.png'
import HowitsWork from '../../assets/images/how-its-work.png'
import GridCircleImage from '../../assets/images/outspokeGridCircle1.png'

const GetStartedThreeSteps = () => {
    return (
        <section id="get_started" className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" />
                                Next Steps
                            </span>
                            <h2 style={{fontSize: 36}}>Get Started in just 3 minutes!</h2>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Sign up for FREE!</h3>
                                    <p className="brighter-p">Sign up with your METRC Api(s) at the link below!</p>
                                    <Link to="https://b1.outspoke.io/signup" className="default-btn" style={{paddingTop: 5, paddingBottom: 5}}>
                                        <i className="flaticon-right"></i>Sign Up<span></span>
                                    </Link>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Initiate Automatic Analytics</h3>
                                    <p className="brighter-p">The system will provide you with an initial review of your supply chain picture based on transactions and inventory data.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Mobilize Your Data!</h3>
                                    <p className="brighter-p">That’s it! Your data will continue to passively flow to Outspoke, meaning you don't have to lift a finger to benefit from this new found data transparency!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image" style={{textAlign: 'center'}}>
                            <img src={GridCircleImage} alt="image" style={{width: '80%'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetStartedThreeSteps;
