import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import activismHeader from '../../assets/images/ACTIVISM-HEADER.png'

const CannabisContent = () => {
    return (
        <section className="projects-area bg-color pt-150 pb-100">
            <div className="container-fluid">
                <div className="row align-items-center">

                    <div className="col-lg-6 col-md-12">
                        <div className="cannabis-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    Cannabis
                                </span>
                                <h2>Success is Measured by the Numbers - Get Your Numbers </h2>
                                <p className="brighter-p">Gain unrivaled data transparency while empowering collaborative purchasing, production and sales decisions using the compliance data you’re generating every day.</p>

                                <p className="brighter-p">Unlock insights on how fast each SKU is moving, how much money you’re making and how much inventory you have left, in app and via email - all it takes is a METRC API and a few minutes to turn your messy compliance data into your new cash cow. </p>

                                <a href="#get_started" className="default-btn">
                                    <i className="flaticon-right"></i>Join Today<span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="cannabis-image">
                            <img src={activismHeader} alt="banner" />
                        </div>
                    </div>

                </div>
            </div>

            {/*<div className="circle-shape1">
                <img src={shape1} alt="banner" style={{width: 200}}  />
            </div>*/}

            {/*<div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Activate Listening</li>
                                    <li><i className="flaticon-tick"></i> Brilliant minds</li>
                                    <li><i className="flaticon-tick"></i> Better. Best. Wow!</li>
                                    <li><i className="flaticon-tick"></i> Branding it better!</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Creating. Results.</li>
                                    <li><i className="flaticon-tick"></i> Expect more</li>
                                    <li><i className="flaticon-tick"></i> Good thinking</li>
                                    <li><i className="flaticon-tick"></i> In real we trust</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication.</p>

                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Stay real. Always.</li>
                                    <li><i className="flaticon-tick"></i> We have you covered</li>
                                    <li><i className="flaticon-tick"></i> We turn heads</li>
                                    <li><i className="flaticon-tick"></i> Your brand, promoted</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}

            {/*<div className="circle-shape1">
                <img src={shape1} alt="banner" style={{width: 150}} />
            </div>*/}
        </section>
    )
}

export default CannabisContent;
