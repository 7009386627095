import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CannabisContent from '../components/Cannabis/CannabisContent'
import GetStartedThreeSteps from '../components/Cannabis/GetStartedThreeSteps'
// import Testimonials from '../components/Cannabis/Testimonials'
import FeaturedPartners from '../components/Cannabis/FeaturedPartners'
import MapSection from "../components/Cannabis/MapSection"
import TextSection from "../components/Cannabis/TextSection"
import FeaturedUsers from "../components/Cannabis/FeaturedUsers"
import Funfacts from "../components/Cannabis/Funfacts"
import { graphql } from 'gatsby'

const AboutUs = ({data}) => {
    return (
        <Layout>
            <Navbar />
            {/*<PageBanner
                pageTitle="About Us"
                homePageText="Home"
                homePageUrl="/"
                activePageText="About Us"
            />*/}
            <CannabisContent />
            <MapSection />
            <TextSection />
            <FeaturedUsers />
            <GetStartedThreeSteps />
            <FeaturedPartners />
            <Funfacts data={data} />
            {/* <Testimonials /> */}
            <Footer />
        </Layout>
    );
}

export const query = graphql`
  query CannabisQuery {
    allMongodbBeMacrostats {
      nodes {
        totalWholesale
        totalRetail
      }
      edges {
        node {
          totalRetail
          totalWholesale
          date
        }
      }
    }
  }
`

export default AboutUs;
