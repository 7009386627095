import React from 'react';
import ReactWOW from 'react-wow';

import Partner1 from '../../assets/images/partner/partner1.png'
import Partner2 from '../../assets/images/partner/partner2.png'
import Partner3 from '../../assets/images/partner/partner3.png'
import Partner4 from '../../assets/images/partner/partner4.png'
import Partner5 from '../../assets/images/partner/partner5.png'
import Partner6 from '../../assets/images/partner/partner6.png'

const FeaturedPartners = () => {
    return (
        <div className="partner-area">
            <div className="container">
              <h4 style={{color: '#003649', textAlign: 'center'}}>Featured Partners</h4>
                <div className="row align-items-center">
                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                            <img
                              src='https://s3.us-west-1.amazonaws.com/outspoke-images/googleLogo1.png'
                              style={{width: '80%'}}
                              alt='METRC' />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                            <img
                              src='https://s3.us-west-1.amazonaws.com/outspoke-images/awsLogo.png'
                              style={{width: '50%'}}
                              alt='METRC' />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <img src='https://s3.us-west-1.amazonaws.com/outspoke-images/sapLogo.png'
                                  style={{width: '80%'}}
                                  alt='METRC' />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <img src='https://s3.us-west-1.amazonaws.com/outspoke-images/netSuiteLogo.png'
                                  style={{width: '60%'}}
                                  alt='METRC' />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <img src='https://s3.us-west-1.amazonaws.com/outspoke-images/masterControlLogo.png'
                                  style={{width: '80%'}}
                                  alt='METRC' />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.6s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                <img src='https://s3.us-west-1.amazonaws.com/outspoke-images/hypurLogo.png'
                                  style={{width: '60%'}}
                                  alt='METRC' />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FeaturedPartners;
