import React from 'react'
import {Link} from 'gatsby'
import ModalVideo from 'react-modal-video'
import learning from '../../assets/images/explore-learning.jpg'
import cannabisDomesticMap from '../../assets/images/cannabisDomesticMap.png'
import Lightbox from 'react-image-lightbox'
import starIcon from '../../assets/images/star-icon.png'

const images = [
    (cannabisDomesticMap),
];

const MapSection = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId='bk7McNUjWgw'
                onClose={() => setIsOpen(!isOpen)}
            />

            <div className="explore-learning-area pt-70 ptb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <span className="sub-title">
                                  <img src={starIcon} alt="banner" />
                                  FIRST FOCUS
                                </span>
                                <h2 style={{fontSize: 42}}>Domestic Cannabis Markets</h2>
                                <p className="brighter-p">Outspoke is organizing, analyzing  and mobilizing required Seed-to-Sale track and trace data, empowering licensed firms to thrive in the transitioning markets  while they prepare for federal legalization.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="single-gallery-item">
                              <Link
                                  to="#"
                                  onClick={e => {
                                      e.preventDefault();
                                      setIsOpenImage(true);
                                      setPhotoIndex(0);}
                                  }
                              >
                                  <img src={cannabisDomesticMap} alt="event" />
                              </Link>
                          </div>
                        </div>
                    </div>
                </div>
                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
        </React.Fragment>
    )
}

export default MapSection
