import React from 'react'
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'

const TextSection = () => {
    return (
        <section className="solutions-area pt-70 pb-20">
            <div className="container">
                <div className="section-title">
                  <h3 className="text-4xl lg:text-6xl font-semibold" style={{color: '#fff'}}>Where would you like to see Outspoke next?</h3>
                  <Link to="/contact" className="default-btn" style={{marginTop: 20}}>
                      <i className="flaticon-right"></i>Message Us<span></span>
                  </Link>
                </div>

            </div>
        </section>
    )
}

export default TextSection;
